import { useState, useRef, useEffect } from 'react';
import { parseNumber } from '../../utils/helpers';
import { ucFirst } from '../../utils/caseconverters';
import CountUp from 'react-countup';
import classNames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import styles from './ChartBlock.module.scss';

const ChartBlock = ({
    title = '',
    text = '',
    link = {},
    items = [],
    size = '',
}) => {
    if (!items.length) {
        return;
    }
    const ref = useRef(null);

    const [mounted, setMounted] = useState(false);
    const [isActiveIndex, setActiveIndex] = useState(null);
    const [clientRect, setClientRect] = useState({ top: null, left: null });
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const isTouchDevice =
            'ontouchstart' in window || navigator.msMaxTouchPoints;

        setIsTouchDevice(isTouchDevice);
    }, []);

    const handleOnMouseMovement = (index) => {
        if (isTouchDevice) {
            return;
        }

        setActiveIndex(index);
        setClientRect({
            top: ref.current.getBoundingClientRect().top,
            left: ref.current.getBoundingClientRect().left,
        });
    };

    const classes = classNames(
        styles['ChartBlock'],
        styles['ChartBlock--' + ucFirst(size)]
    );

    return (
        mounted && (
            <div ref={ref} className={classes}>
                <div className={styles['ChartBlock__Container']}>
                    <h2 className={styles['ChartBlock__Title']}>
                        <span>{title}</span>
                        <span className={styles['ChartBlock__Text']}>
                            {text}
                        </span>
                    </h2>
                    <ul className={styles['ChartBlock__List']}>
                        {items.map((item, index) => (
                            <ListItem
                                key={index}
                                index={index}
                                isActiveIndex={isActiveIndex}
                                isTouchDevice={isTouchDevice}
                                clientRect={clientRect}
                                handleOnMouseMovement={handleOnMouseMovement}
                                {...item}
                            />
                        ))}
                    </ul>
                    <div className={styles['ChartBlock__Button']}>
                        <Button
                            link={link}
                            modifiers={['tertiary', 'borderRadius']}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

const ListItem = ({
    isActiveIndex = null,
    index = null,
    value = '',
    link = {},
    image = {},
    handleOnMouseMovement,
}) => {
    const imageRef = useRef(null);

    const [number, setNumber] = useState(parseNumber(value));
    const { decimal, decimals, numberInt, prefix, suffix } = number;

    const classes = classNames(styles['ChartBlock__Link']);

    const itemClasses = classNames(styles['ChartBlock__Item'], {
        [styles['ChartBlock__Item--InActive']]:
            isActiveIndex !== index && isActiveIndex !== null,
        [styles['ChartBlock__Item--Active']]:
            isActiveIndex === index && isActiveIndex !== null,
    });

    const imageClasses = classNames(styles['ChartBlock__Image'], {
        [styles['ChartBlock__Image--Visible']]: isActiveIndex === index,
    });

    return (
        <>
            <figure ref={imageRef} className={imageClasses}>
                <div className={styles['ChartBlock__Blend']}>
                    <Image image={image} sizes={'(min-width: 1800px) 1080px'} />
                </div>
            </figure>
            <li
                className={itemClasses}
                onMouseEnter={() => handleOnMouseMovement(index)}
                onMouseLeave={() => handleOnMouseMovement(null)}>
                <a className={classes} href={link.href} target={link.target}>
                    <span
                        className={styles['ChartBlock__LinkTitle']}
                        data-text={link.title}>
                        <span className={styles['ChartBlock__Icon']}>
                            <Icon
                                icon={'arrowRight'}
                                dimensions={{ width: '32px', height: '32px' }}
                            />
                        </span>
                        {link.title}
                    </span>
                    <CountUp
                        start={numberInt - 0.1}
                        end={numberInt}
                        duration={1}
                        decimals={decimals}
                        decimal={decimal}
                        prefix={prefix}
                        suffix={suffix}
                        useEasing={false}
                        enableScrollSpy={true}
                        scrollSpyDelay={200}
                        scrollSpyOnce={true}>
                        {({ countUpRef }) => (
                            <span
                                ref={countUpRef}
                                className={styles['ChartBlock__Value']}
                            />
                        )}
                    </CountUp>
                </a>
            </li>

            {/* 
            <li
                className={styles['ChartBlock__Item']}
                onMouseEnter={() => handleOnMouseMovement(index)}
                onMouseLeave={() => handleOnMouseMovement(null)}>
                <a className={classes} href={link.href} target={link.target}>
                    <span
                        className={styles['ChartBlock__LinkTitle']}
                        data-text={link.title}>
                        <span className={styles['ChartBlock__Icon']}>
                            <Icon
                                icon={'arrowRight'}
                                dimensions={{ width: '32px', height: '32px' }}
                            />
                        </span>
                        {link.title}
                    </span>
                    <CountUp
                        start={numberInt - 0.1}
                        end={numberInt}
                        duration={1}
                        decimals={decimals}
                        decimal={decimal}
                        prefix={prefix}
                        suffix={suffix}
                        useEasing={false}
                        enableScrollSpy={true}
                        scrollSpyDelay={200}
                        scrollSpyOnce={true}>
                        {({ countUpRef }) => (
                            <span
                                ref={countUpRef}
                                className={styles['ChartBlock__Value']}
                            />
                        )}
                    </CountUp>
                </a>
                <figure ref={imageRef} className={imageClasses}>
                    <div className={styles['ChartBlock__Blend']}>
                        <Image
                            image={image}
                            sizes={'(min-width: 1800px) 1080px'}
                        />
                    </div>
                </figure>
            </li> */}
        </>
    );
};

ChartBlock.propTypes = {};

export default ChartBlock;
